import * as React from "react"
import Seo from "../components/seo";
import "./index.scss";

const IndexPage = () => {
  return (
    <main className="Index">
      <Seo title="En construction" />
      <h1>
        Vendre mon entreprise
      </h1>
      <div className="wip">
        <div className="logo"></div>
        <p className="description">
          Site actuellement en construction
        </p>
      </div>
            
    </main>
  )
}

export default IndexPage
